.main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 75px);
}

.content-container {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 1px 3px 20px 2px rgba(204,204,204,0.19);
    border: 1px solid #ccc;
    max-width: 500px;
}
.main-content {
    font-size: 40px;
    margin-bottom: 10px;
}
.sub-content {
    text-align: center;
    color: #343a40;
}