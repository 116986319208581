.main {
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.main-video {
    width: 70%;
    margin: 10px;
}
.main-video-supplements {
    width: 30%;
    margin: 10px;

}