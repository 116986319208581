.header {
    background-color: #343a40;
    padding: 5px;
    display:flex;
    align-items: center;
    margin-bottom: 10px;
    padding: .5rem 1rem;

}
.header .header-logo-container {
    color:white;
    display:flex;
    align-items: center;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
}