.card-body-header {
    display: flex;

}
.card-title {
    margin-right: 10px;
}

video::cue { color: white;}

.cue-error { color: red }
.cue-warning { color: orange }
.cue-info { color: black }
.cue-debug { color: teal }
.cue-telemetry { color: gray }
.transcript-body {
    max-height: 350px;
    overflow-y: scroll;
}

.list-group-item.active{
    color: #FF6600;
    background-color:transparent;
    border-top: 1px solid transparent;
    border-bottom:1px solid rgba(0, 0, 0, 0.125);
}
.list-group {
    margin-bottom: 20px;
}